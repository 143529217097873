<template>
  <div class="d-flex">
    <div class="form-alert-icon-container mr-2 ml-1">
      <VIcon
        size="12"
        color="tt-light-yellow"
      >
        fas fa-exclamation-circle
      </VIcon>
    </div>
    <div class="form-alert-text-container tt-text-body-2">
      <p class="mb-0">
        На этот продукт уже {{ $tc('pluralize.licence', totalLicence, { value : totalLicence }) }}
      </p>
      <p class="mb-2">
        Вы можете добавить лицензию на другие даты или с другим типом.
      </p>

      <p
        v-for="(licence, i) in allAppLicence"
        :key="i"
        class="mb-0"
      >
        С {{ licence.from }} до {{ licence.to }} • {{ licence.type === 'company' ? 'Компания' : 'Аккаунт' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyLicenseCreateAlert',
  props: {
    totalLicence: {
      required: true,
      type: Number,
    },
    allAppLicence: {
      required: true,
      type: Array,
    },
  },
};
</script>
