<template>
  <div class="license-form-container mt-4">
    <div class="license-form-title-container mb-4">
      <span class="license-form-title-subtitle tt-text-body-2 tt-light-mono-64--text">
        {{ appBundleName }}
      </span>
      <h2 class="license-form-title tt-text-title-2">
        {{ appName }}
      </h2>
    </div>
    <CompanyLicenseCreateAlert
      v-if="totalLicence"
      class="license-form-alert pa-3"
      :total-licence="totalLicence"
      :all-app-licence="allAppLicence"
    />
    <div class="d-flex mt-4">
      <VForm class="license-form">
        <VContainer fluid>
          <VRow justify="space-between">
            <VCol
              cols="6"
              class="license-form-col pa-0"
            >
              <DateTime
                v-model="date"
                class="license-form-date-time tt-text-body-2"
                date-lable="Дата начала"
                date-require
                :date-rules="[dateRule]"
              />
            </VCol>

            <VCol
              cols="6"
              class="license-form-col pa-0"
            >
              <TTTextField
                v-model.number="licenceData.duration.days"
                class="tt-text-body-2"
                label="Срок действия"
                placeholder="Введите количество дней"
                :type="'number'"
                :rules="[daysRule]"
                min="0"
              />
            </VCol>
          </VRow>

          <VRow
            justify="space-between"
            class="mt-9"
          >
            <VCol
              cols="6"
              class="license-form-col pa-0"
            >
              <VMenu
                ref="menu"
                v-model="isShowPicker"
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <TTTextField
                    v-model="time"
                    label="Время"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <VTimePicker
                  v-if="isShowPicker"
                  v-model="time"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menu.save(time)"
                />
              </VMenu>
            </VCol>

            <VCol
              cols="6"
              class="license-form-col pa-0"
            >
              <TTSelect
                v-model="timezone"
                label="Таймзона"
                placeholder="Выберите таймзону"
                :items="timezones"
              />
            </VCol>
          </VRow>

          <VRow
            justify="space-between"
            class="mt-9"
          >
            <VCol
              cols="6"
              class="license-form-col pa-0"
            >
              <TTSelect
                v-model="licenceData.licensee"
                :items="selectTypes"
                class="tt-text-body-2"
                label="Тип"
                placeholder="Выберите тип"
                :rules="[typeRule]"
              />
            </VCol>
            <VCol
              cols="6"
              class="license-form-col pa-0"
            >
              <TTTextField
                v-model.number="licenceData.totalUsers"
                class="tt-text-body-2"
                label="Пользователи"
                placeholder="Введите количество пользователей"
                :type="'number'"
                :rules="[usersRule]"
                min="0"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
      <VDivider
        class="license-form-border ml-6 mt-7"
        vertical
      />
      <TTBtn
        class="license-form-delete ml-3"
        color="tt-dark-red"
        icon
        @click="emitDelete"
      >
        <VIcon>fal fa-trash-alt</VIcon>
      </TTBtn>
    </div>
  </div>
</template>

<script>
import DateTime from './ui/DateTime.vue';
import CompanyLicenseCreateAlert from './CompanyLicenseCreateAlert.vue';

export default {
  name: 'CompanyLicenseCreateForm',
  components: {
    CompanyLicenseCreateAlert,
    DateTime,
  },
  props: {
    licence: {
      type: Object,
      required: true,
    },
    accountId: {
      required: true,
      type: String,
    },
    companyId: {
      required: true,
      type: String,
    },
    totalLicence: {
      required: true,
      type: Number,
    },
    allAppLicence: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      licenceData: {
        appName: this.licence.appName,
        isValid: false,
        licensee: {
          companyId: '',
          accountId: '',
        },
        duration: {
          startsAt: '',
          days: null,
        },
        totalUsers: null,
        involvedUsers: 0,
        active: true,
      },
      isShowPicker: false,
      time: '00:00',
      date: '',
      timezone: '',
      timezones: [
        {
          text: '+12:00',
          value: 720,
        },
        {
          text: '+11:00',
          value: 660,
        },
        {
          text: '+10:00',
          value: 600,
        },
        {
          text: '+09:00',
          value: 540,
        },
        {
          text: '+08:00',
          value: 480,
        },
        {
          text: '+07:00',
          value: 420,
        },
        {
          text: '+06:00',
          value: 360,
        },
        {
          text: '+05:00',
          value: 300,
        },
        {
          text: '+04:00',
          value: 240,
        },
        {
          text: '+03:00',
          value: 180,
        },
        {
          text: '+02:00',
          value: 120,
        },
        {
          text: '+01:00',
          value: 60,
        },
        {
          text: '00:00',
          value: 0,
        },
        {
          text: '-01:00',
          value: -60,
        },
        {
          text: '-02:00',
          value: -120,
        },
        {
          text: '-03:00',
          value: -180,
        },
        {
          text: '-04:00',
          value: -240,
        },
        {
          text: '-05:00',
          value: -300,
        },
        {
          text: '-06:00',
          value: -360,
        },
        {
          text: '-07:00',
          value: -420,
        },
        {
          text: '-08:00',
          value: -480,
        },
        {
          text: '-09:00',
          value: -540,
        },
        {
          text: '-10:00',
          value: -600,
        },
        {
          text: '-11:00',
          value: -660,
        },
        {
          text: '-12:00',
          value: -720,
        },
      ],
      selectTypes: [
        {
          value: { accountId: this.accountId },
          text: 'Аккаунт',
        },
        {
          value: { companyId: this.companyId },
          text: 'Компания',
        },
      ],
      rulesAlerts: {
        daysRule: 'Срок действия должен быть больше 1 дня и меньше 1826 дней',
        usersRule: 'Пользователей должно быть 1 или больше',
        dateRule: 'Дата начала пересекается с уже выданной лицензией',
        typeRule: 'Тип пересекается с уже выданной лицензией',
        noDate: 'Выберите дату',
        noType: 'Выберите тип',
        noDays: 'Введите количество дней',
        noUsers: 'Введите количество пользователей',
        notCorrectValue: 'Пользователей должно быть меньше 500000',
      },
    };
  },

  LICENCE_BUNDLE_NAME: {

    multi_level_test: 'learning_bundle',
    assessment: 'learning_bundle',
    expert_assessment: 'learning_bundle',
    skilldev: 'learning_bundle',
    lxp: 'learning_bundle',

    pms: 'goals_bundle',

    engagement: 'engagement_bundle',
    engagement_burnout: 'engagement_bundle',
    engagement_full: 'engagement_bundle',
    tsxass: 'engagement_bundle',

    snami: 'snami_bundle',

    empty: 'empty_bundle',
  },

  computed: {
    appName() {
      return this.$t(`products.${this.licence.appName}`) || '';
    },
    appBundleName() {
      return this.$t(`products.${this.$options.LICENCE_BUNDLE_NAME[this.licence.appName]}`) || '';
    },
    dateWithNoOffset() {
      const date = this.$dayjs(this.date).format();
      return `${date.slice(0, 19)}Z`;
    },
    licenceType() {
      const { licensee } = this.licenceData;
      let type;

      if (licensee.companyId) type = 'company';
      if (licensee.accountId) type = 'account';

      return type;
    },
    hasAllDataForDate() {
      const { duration } = this.licenceData;
      return !!(this.licenceType && duration.startsAt && duration.days);
    },
    daysRule() {
      if (!this.licenceData.duration.days && this.licenceData.duration.days !== 0) {
        return this.rulesAlerts.noDays;
      }

      if (!this.hasAllDataForDate && this.licenceData.duration.days) {
        return true;
      }

      return (this.licenceData.duration.days > 0 && this.licenceData.duration.days <= 1825)
        || this.rulesAlerts.daysRule;
    },
    usersRule() {
      if (this.licenceData.totalUsers >= 500000) {
        return this.rulesAlerts.notCorrectValue;
      }

      if (!this.licenceData.totalUsers && this.licenceData.totalUsers !== 0) {
        return this.rulesAlerts.noUsers;
      }

      return (this.licenceData.totalUsers > 0)
        || this.rulesAlerts.usersRule;
    },
    dateRule() {
      if (!this.licenceData.duration.startsAt) {
        return this.rulesAlerts.noDate;
      }

      if (!this.hasAllDataForDate && this.licenceData.duration.startsAt) {
        return true;
      }

      return (this.licenceData.duration.startsAt
        && this.dateAndTypeNotIntersect)
        || this.rulesAlerts.dateRule;
    },
    typeRule() {
      if (!this.licenceType) {
        return this.rulesAlerts.noType;
      }

      if (!this.hasAllDataForDate && this.licenceType) {
        return true;
      }

      return (this.licenceType
        && this.dateAndTypeNotIntersect)
        || this.rulesAlerts.typeRule;
    },
    dateAndTypeNotIntersect() {
      const { duration } = this.licenceData;

      if (!this.licenceType || !duration.startsAt || !duration.days) {
        return false;
      }

      const type = this.licenceType;
      const start = this.$dayjs(duration.startsAt).valueOf();
      const end = this.$dayjs(duration.startsAt).add(duration.days, 'day').valueOf();

      const formattedAllApp = this.allAppLicence.map((el) => ({
        ...el,
        isDateIntersect: this.intersectCheck(el.modifiedFrom, el.modifiedTo, start, end),
      }));

      const isIntersected = formattedAllApp.find((el) => {
        if (el.isDateIntersect) {
          return el.type === type;
        }
        return false;
      });

      return !isIntersected;
    },
    isDaysValid() {
      return this.daysRule !== this.rulesAlerts.daysRule && this.daysRule !== this.rulesAlerts.noDays;
    },
    isUsersValid() {
      return this.usersRule !== this.rulesAlerts.usersRule
        && this.usersRule !== this.rulesAlerts.noUsers
        && this.usersRule !== this.rulesAlerts.notCorrectValue;
    },
    isDateValid() {
      return this.dateRule !== this.rulesAlerts.dateRule && this.dateRule !== this.rulesAlerts.noDate;
    },
    isTypeValid() {
      return this.typeRule !== this.rulesAlerts.typeRule && this.typeRule !== this.rulesAlerts.noType;
    },
    isValid() {
      const {
        isTypeValid, isDateValid, isUsersValid, isDaysValid,
      } = this;

      return isTypeValid && isDateValid && isUsersValid && isDaysValid;
    },
  },
  watch: {
    licenceData: {
      deep: true,
      handler() {
        this.$emit('change', {
          ...this.licenceData,
          isValid: this.isValid,
        });
      },
    },
    time() {
      if (this.date) {
        this.generateStartDate();
      }
    },
    date() {
      this.generateStartDate();
    },
    timezone() {
      if (this.date) {
        this.generateStartDate();
      }
    },
  },
  created() {
    this.timezone = -this.$dayjs().$d.getTimezoneOffset();
  },
  methods: {
    generateStartDate() {
      let startDate = this.$dayjs.utc(this.dateWithNoOffset).format();
      const newTime = `T${this.time}:00`;
      startDate = startDate.replace(/T00:00:00/g, newTime);
      const actualDate = this.$dayjs.utc(startDate).utcOffset(this.timezone);
      this.licenceData.duration.startsAt = `${actualDate.format().slice(0, 19)}.000Z`;
    },
    intersectCheck(activeStart, activeEnd, start, end) {
      return start <= activeEnd && activeStart <= end;
    },
    emitDelete() {
      this.$emit('delete', this.licenceData);
    },
  },
};
</script>

<style lang="scss" scoped>
  .license-form {
    width: 744px;
  }
  .license-form-border {
    height: 245px;
  }
  .license-form-delete {
    margin-top: 130px;
  }
  .license-form-alert {
    width: 744px;
    background: map-get($tt-light-yellow, 'pale');
    border-radius: 4px;
  }
  .license-form-col {
    max-width: 360px;
  }
  .license-form-date-time ::v-deep div {
    max-width: 100%;
    flex: 1 1 100%;
  }
  .license-form ::v-deep .v-input__control,
  .license-form ::v-deep .v-input__slot {
    min-height: 44px;
  }
  .license-form-date-time ::v-deep .v-input__icon {
    justify-content: flex-end;
  }
</style>
